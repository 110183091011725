import React, { useState } from "react";
import { useDispatch } from "react-redux";
import "./styles.css";
import { sessionSlice } from "../../../store/sessionSlice";

const Input = ({
  placeholder,
  label = "",
  value,
  onChange,
  variant = "",
  error = false,
  errorMsg = "",
  className = "",
  maxLength = "",
}) => {
  const dispatch = useDispatch();
  return (
    <div
      className={`column ${className} ${
        errorMsg.length > 0 ? "with-error-msg" : ""
      }`}
    >
      <div
        className={`input-container row ${
          label.length === 0 ? "no-label" : ""
        }`}
      >
        {label.length > 0 && (
          <label htmlFor="name" className={`label-text ${variant}`}>
            {label}
          </label>
        )}
        <input
          name="name"
          type="text"
          className={`input ${variant}`}
          placeholder={placeholder}
          value={value}
          maxLength={maxLength}
          onChange={(e) => onChange(e.target.value)}
          onFocus={(e) => {
            dispatch(sessionSlice.actions.isTyping({ typing: true }));
          }}
          onBlur={(e) => {
            dispatch(sessionSlice.actions.isTyping({ typing: false }));
          }}
        />
      </div>
      {error && <p className={`input-error-msg ${variant}`}>{errorMsg}</p>}
    </div>
  );
};
export default Input;
