import React, { useState } from "react";
import "./styles.css";
import FloatingContainer from "../FloatingContainer";
import Input from "../Input";
import Button from "../Button";
import Switch from "../Switch";
import ZoomOutIcon from "../ZoomOutIcon";
import ZoomInIcon from "../ZoomInIcon";
import DeleteIcon from "../DeleteIcon";
import BlockIcon from "../BlockIcon";
import RecenterIcon from "../RecenterIcon";

const validHostnames = [
  "killcloud.nyc3.cdn.digitaloceanspaces.com",
  "molecularweb.epfl.ch",
];

const isValidUrl = (urlString) => {
  var urlPattern = new RegExp(
    "^(https?:\\/\\/)?" + // validate protocol
      "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // validate domain name
      "((\\d{1,3}\\.){3}\\d{1,3}))" + // validate OR ip (v4) address
      "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // validate port and path
      "(\\?[;&a-z\\d%_.~+=-]*)?" + // validate query string
      "(\\#[-a-z\\d_]*)?$",
    "i"
  ); // validate fragment locator

  const isUrl = !!urlPattern.test(urlString);

  if (!isUrl) {
    return false;
  } else {
    const myUrl = new URL(urlString);
    return validHostnames.includes(myUrl.hostname);
  }
};

const ElementsMenu = ({
  onClose,
  onSubmit,
  onScale,
  onDelete,
  onRecenter,
  elements,
  onSlideSubmit,
  onToggleEnable,
  isAdmin,
  onReset,
}) => {
  const [elName, setElName] = useState("");
  const [elURL, setElURL] = useState("");
  const [slideURL, setSlideURL] = useState("");
  const [urlError, setUrlError] = useState(false);

  const handleSubmit = () => {
    if (elName.length <= 0) {
      return;
    }

    if (isValidUrl(elURL)) {
      const data = {
        name: elName,
        url: elURL,
      };

      onSubmit(data);

      setElName("");
      setElURL("");
    } else {
      setUrlError(true);
    }
  };

  const handleSubmitUrl = () => {
    if (slideURL.length > 0 && isValidUrl(slideURL)) {
      const data = {
        url: slideURL,
      };
      onSlideSubmit(data);
      setSlideURL("");
    }
  };

  const handleScaleUp = (id) => {
    onScale(id, "up");
  };
  const handleScaleDown = (id) => {
    onScale(id, "down");
  };
  const handleRecenter = (id) => {
    onRecenter(id);
  };
  const handleDelete = (id) => {
    onDelete(id);
  };
  const handleEnable = (id) => {
    onToggleEnable(id);
  };
  const handleReset = () => {
    onReset();
  };
  const handleExport = () => {
    let string = "";
    if (elements.length > 0) {
      elements.forEach((element) => {
        string += `createElement("${element.name}", "${element.url}", false, ${
          element.scale
        }, {x:${element.position.x.toFixed(2)}, y:${element.position.y.toFixed(
          2
        )}, z:${element.position.z.toFixed(
          2
        )}}, {x:${element.quaternion.x.toFixed(
          2
        )}, y:${element.quaternion.y.toFixed(
          2
        )}, z:${element.quaternion.z.toFixed(
          2
        )}, w:${element.quaternion.w.toFixed(2)}})\n`;
      });
    }
    console.log(string);
  };

  return (
    <FloatingContainer onClose={onClose} offset="elements" className="elements">
      {isAdmin && (
        <>
          {/* <p className="settings-text">Set here the URL of the new slide:</p>
          <div className="slide-options row">
            <div className="settings-input-container row">
              <Input
                placeholder="My URL"
                label="Img URL"
                value={slideURL}
                onChange={(text) => setSlideURL(text)}
              />
            </div>
            <Button
              className="img"
              variant="small"
              handleClick={handleSubmitUrl}
            >
              Submit
            </Button>
          </div> */}
          <p className="settings-text">
            Type the name of the element, paste the URL and click submit.
            Remember that the element must have been created in PDB2AR.
          </p>
          <Input
            placeholder="My Element"
            label="Name"
            maxLength="16"
            value={elName}
            onChange={(text) => setElName(text)}
          />
          <Input
            placeholder="My URL"
            label="URL"
            value={elURL}
            onChange={(text) => {
              setElURL(text);
              setUrlError(false);
            }}
            error={urlError}
            errorMsg="That's not a valid URL :("
          />
          <Button
            className="submit-btn"
            variant="small"
            handleClick={handleSubmit}
          >
            Submit
          </Button>
        </>
      )}

      {/* <Button className="submit-btn" variant="small" handleClick={handleExport}>
            Export
          </Button> */}
      {elements.length === 0 && (
        <p className="list-title msg-text">
          Currently there are no elements in the scene
        </p>
      )}

      {elements.length > 0 && (
        <div className="list-container column">
          <p className="list-title">Elements</p>
          <div className="scroll-list">
            {elements.map((element) => !element.name.includes("label") && (
              <div className="list-row row" key={`${element.id}`}>
                <p className="list-text">{element.name}</p>
                {isAdmin && (
                  <div className="row">
                    <RecenterIcon handleClick={() => handleRecenter(element.id)} />
                    <ZoomInIcon handleClick={() => handleScaleUp(element.id)} />
                    <ZoomOutIcon
                      handleClick={() => handleScaleDown(element.id)}
                    />
                    <BlockIcon
                      handleClick={() => handleEnable(element.id)}
                      isActive={!element.isEnabled}
                    />
                    <DeleteIcon handleClick={() => handleDelete(element.id)} />
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      )}
      {elements.length > 0 && isAdmin && (
        <>
          <Button
            className="submit-btn"
            variant="small"
            handleClick={handleReset}
          >
            Reset Scene
          </Button>
        </>
      )}
    </FloatingContainer>
  );
};
export default ElementsMenu;
