import React, { useEffect } from "react";
import LogoHeader from "../LogoHeader";
import "./index.css";
import Button from "../Button";

const HelpOverlay = ({ isActive, onClose }) => {
  return (
    <div className={`overlay ${isActive ? "active" : ""}`}>
      <LogoHeader />
      <div className="overlay-container column">
        <p className="overlay-text">
          Access rooms with preset content or setup your own material for
          education and discussions creating the elements you want to visualize
          in{" "}
          <span>
            <a
              target="_blank"
              rel="noopener noreferrer"
              className="inline-link"
              href="https://molecularweb.epfl.ch/pages/pdb2ar.html"
            >
              PDB2AR,{" "}
            </a>
            where you can build them from raw PDB files, PDB entries, or by
            uploading your own VMD-made virtual scenes.
          </span>
        </p>

        <p className="overlay-text">
          Once you have created the elements in PDB2AR, copy and paste the 3D
          model URL you have received via email directly in the elements menu on
          the right side of the screen. Keep in mind that you will only be able
          to add elements to a session if you are the host.
        </p>

        <p className="overlay-text">
          Each session has a limit of 15 minutes. After that time the session
          will be automatically closed. For personalized o customized solutions
          contact us at luciano.abriata@epfl.ch and
          fabio.cortesrodriguez@epfl.ch
        </p>

        <p className="overlay-text">
          Do you want to help us improve this tool? Answer this short{" "}
          <span>
            <a
              target="_blank"
              rel="noopener noreferrer"
              className="inline-link"
              href="https://docs.google.com/forms/d/e/1FAIpQLSexPv7SGdTjjt_AVCDBsmY1-cvrDJZZ_CkVO-dPn4It_4Vqng/viewform"
            >
              survey
            </a>
          </span>{" "}
          to give us some feedback.
        </p>

        <p className="overlay-text">
          To know more about the website, its features and how to use them,
          click{" "}
          <span>
            <a
              target="_blank"
              rel="noopener noreferrer"
              className="inline-link"
              href="https://lucianosphere.medium.com/coming-up-in-the-next-weeks-free-multiuser-immersive-chemistry-and-biology-at-your-fingertips-d4b0ba32cf4b"
            >
              here
            </a>
          </span>
          .
        </p>
      </div>
      <Button handleClick={onClose}>Close</Button>
    </div>
  );
};
export default HelpOverlay;
