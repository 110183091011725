import React from "react";
import "./styles.css";

const RecenterIcon = ({ handleClick }) => {
  return (
    <div className="icon-container list">
      <a className="icon-anchor" onClick={handleClick}>
        <svg
          className="svg-container-small element-icon"
          width="100%"
          height="100%"
          viewBox="0 0 512 512"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          xmlSpace="preserve"
          xmlnsserif="http://www.serif.com/"
        >
          <g
            className="el-path-small"
            transform="matrix(16.85,0,0,16.85,53.8,53.8)"
          >
            <path
              d="M5.54,8.46L2,12L5.54,15.54L7.3,13.77L5.54,12L7.3,10.23L5.54,8.46ZM12,18.46L10.23,16.7L8.46,18.46L12,22L15.54,18.46L13.77,16.7L12,18.46ZM18.46,8.46L16.7,10.23L18.46,12L16.7,13.77L18.46,15.54L22,12L18.46,8.46ZM8.46,5.54L10.23,7.3L12,5.54L13.77,7.3L15.54,5.54L12,2L8.46,5.54Z"
            />
            <circle cx="12" cy="12" r="3" />
          </g>
        </svg>

        <div className="tooltip-small">
          <p>Recenter</p>
        </div>
      </a>
    </div>
  );
};
export default RecenterIcon;
