import React from "react";
import "./styles.css";

const RoomCard = ({ name, description, imgUrl, handleClick }) => {
  return (
    <div className="room-card" onClick={handleClick}>
      <img
        className="thumb-img"
        src={imgUrl}
      ></img>
      <p className="room-card-name">{name}</p>
    </div>
  );
};
export default RoomCard;
