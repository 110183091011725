import React from "react";
import "./styles.css";

const InviteIcon = ({ isActive, handleClick }) => {
  return (
    <div className="icon-container icon-margin-btm" id="invite">
      <a className="icon-anchor" onClick={handleClick}>
        <svg
          className="svg-container element-icon"
          width="100%"
          height="100%"
          viewBox="0 0 512 512"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          xmlSpace="preserve"
          xmlnsserif="http://www.serif.com/"
        >
          <g transform="matrix(1.79021,0,0,1.79021,53.7063,19.6923)">
            <circle
              className={`el-circle ${isActive ? "active" : ""}`}
              cx="113"
              cy="132"
              r="143"
            />
          </g>
          <g
            className="el-path"
            transform="matrix(15.3864,0,0,15.3864,71.3636,71.3636)"
          >
            <path
              d="M15,12C17.21,12 19,10.21 19,8C19,5.79 17.21,4 15,4C12.79,4 11,5.79 11,8C11,10.21 12.79,12 15,12ZM15,6C16.1,6 17,6.9 17,8C17,9.1 16.1,10 15,10C13.9,10 13,9.1 13,8C13,6.9 13.9,6 15,6ZM15,14C12.33,14 7,15.34 7,18L7,20L23,20L23,18C23,15.34 17.67,14 15,14ZM9,18C9.22,17.28 12.31,16 15,16C17.7,16 20.8,17.29 21,18L9,18ZM6,15L6,12L9,12L9,10L6,10L6,7L4,7L4,10L1,10L1,12L4,12L4,15L6,15Z"
            />
          </g>
        </svg>
        {!isActive && (
          <div className="tooltip">
            <p>Invite people</p>
            <div className="triangle"></div>
          </div>
        )}
      </a>
    </div>
  );
};
export default InviteIcon;
