import React from "react";
import "./styles.css";

const BlockIcon = ({ handleClick, isActive }) => {
  return (
    <div className="icon-container list">
      <a className="icon-anchor" onClick={handleClick}>
        <svg
          className="svg-container-small element-icon"
          width="100%"
          height="100%"
          viewBox="0 0 512 512"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          xmlSpace="preserve"
          xmlnsserif="http://www.serif.com/"
        >
          <g
            className={`el-path-small block ${isActive ? "active" : ""}`}
            transform="matrix(16.85,0,0,16.85,53.8,53.8)"
          >
            <path d="M12,2C6.48,2 2,6.48 2,12C2,17.52 6.48,22 12,22C17.52,22 22,17.52 22,12C22,6.48 17.52,2 12,2ZM4,12C4,7.58 7.58,4 12,4C13.85,4 15.55,4.63 16.9,5.69L5.69,16.9C4.63,15.55 4,13.85 4,12ZM12,20C10.15,20 8.45,19.37 7.1,18.31L18.31,7.1C19.37,8.45 20,10.15 20,12C20,16.42 16.42,20 12,20Z" />
          </g>
        </svg>

        <div className="tooltip-small">
          <p>{isActive ? "Unblock" : "Block"}</p>
        </div>
      </a>
    </div>
  );
};
export default BlockIcon;
