import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isConnected: false,
  isConnectedToASession: false,
  isEstablishingConnection: false,
  isTryingRoom: false,
  userType: "guest",
  isVr: false,
  isAr: false,
  isTouch: false,
  withAudio: false,
  isSessionMuted: false,
  areGuestsMuted: false,
  amIMuted: false,
  id: "",
  rtpCapabilities: null,
  audioUsers: [],
  roomId: "",
  attendeeId: "",
  availableRooms: null,
  trialSlide: "",
  trialScene: null,
  isTyping: false,
  noRoomFound: false,
  isShowingInfoModal: false,
  isShowingOverlay: false,
};

export const sessionSlice = createSlice({
  name: "session",
  initialState,
  reducers: {
    startConnecting: (state) => {
      state.isEstablishingConnection = true;
    },
    receivedAvailableRooms: (state, action) => {
      state.availableRooms = action.payload.scenes;
      state.trialSlide = action.payload.trialSlide;
      state.trialScene = action.payload.trialScene;
    },
    receivedStartingPackage: (state, action) => {
      state.withAudio = action.payload.withAudio;
      state.rtpCapabilities = action.payload.rtpCapabilities;
      state.isSessionMuted = action.payload.isSessionMuted;
      state.isConnectedToASession = true;
      state.roomId = action.payload.roomId;
      state.attendeeId = action.payload.attendeeId || "";
      state.userType = action.payload.isAttendee ? "attendee" : "guest";
    },
    isVrUser: (state, action) => {
      state.isVr = true;
    },
    isArUser: (state, action) => {
      state.isAr = true;
    },
    isTouchScreen: (state, action) => {
      state.isTouch = true;
    },
    connectionStablished: (state, action) => {
      state.isConnected = true;
      state.isEstablishingConnection = false;
      state.id = action.payload.id;
    },
    connectionLost: (state, action) => {
      state.isConnected = false;
      state.isEstablishingConnection = false;
      state.id = "";
      state.withAudio = false;
      state.isSessionMuted = false;
      state.areGuestsMuted = false;
      state.isConnectedToASession = false;
      state.roomId = "";
      state.availableRooms = null;
      state.isConnectedToASession = false;
      state.audioUsers = [];
    },
    sessionClosed: (state, action) => {
      state.withAudio = false;
      state.isSessionMuted = false;
      state.areGuestsMuted = false;
      state.isConnectedToASession = false;
      state.roomId = "";
      state.attendeeId = "";
      state.isConnectedToASession = false;
      state.audioUsers = [];
    },
    toggleAudioChannel: (state, action) => {
      state.withAudio = action.payload.withAudio;
    },
    toggleMute: (state, action) => {
      state.isSessionMuted = action.payload.isSessionMuted;
    },
    toggleMuteGuests: (state, action) => {
      state.areGuestsMuted = action.payload.areGuestsMuted;
    },
    setAdmin: (state, action) => {
      state.userType = "admin";
      state.isShowingInfoModal = true;
    },
    toggleMuteMyself: (state, action) => {
      state.amIMuted = action.payload.amIMuted;
    },
    toggleInfoModal: (state, action) => {
      state.isShowingInfoModal = !state.isShowingInfoModal;
    },
    requestTrial: (state, action) => {
      state.isTryingRoom = true;
    },
    leaveTrial: (state, action) => {
      state.isTryingRoom = false;
    },
    isTyping: (state, action) => {
      state.isTyping = action.payload.typing;
      if (action.payload.typing) {
        state.noRoomFound = false;
      }
    },
    noRoomFound: (state, action) => {
      state.noRoomFound = true;
    },
    resetErrors: (state, action) => {
      state.noRoomFound = false;
    },
    toggleHelp: (state, action) => {
      state.isShowingOverlay = !state.isShowingOverlay;
    },
    emitToggleAudioChannel: (state, action) => {
      return;
    },
    emitToggleMute: (state, action) => {
      return;
    },
    emitToggleMuteGuests: (state, action) => {
      return;
    },
    emitRequestNewRoom: (state, action) => {
      return;
    },
    emitRequestJoinRoom: (state, action) => {
      return;
    },
    emitResetScene: (state, action) => {
      return;
    },
    emitCloseSession: (state, action) => {
      return;
    },
    emitLeaveSession: (state, action) => {
      return;
    },
  },
});
