import React, { useState } from "react";
import "./styles.css";
import Button from "../Button";
import ExitIcon from "../ExitIcon";
import CloseIcon from "../CloseIcon";
import VrIcon from "../VrIcon";
import ArIcon from "../ArIcon";
import MuteIcon from "../MuteIcon";
import HelpIcon from "../HelpIcon";

const BottomMenu = ({
  onEnterVR,
  onEnterAR,
  onCloseSession,
  onLeaveSession,
  onToggleMuteMyself,
  onCloseTrial,
  isVrUser,
  isArUser,
  isAdmin,
  isAudioEnabled,
  amIMuted,
  isAttendee,
  isTryingRoom,
  isConnectedToASession,
  onShowHelpOverlay,
}) => {
  return (
    <div className="bottom-menu-container">
      {isVrUser && (!isAttendee || isTryingRoom) && (
        <VrIcon handleClick={onEnterVR} />
      )}

      {isArUser && (!isAttendee || isTryingRoom) && (
        <ArIcon handleClick={onEnterAR} />
      )}

      {isAdmin && isConnectedToASession && (
        <CloseIcon handleClick={onCloseSession} />
      )}

      {isTryingRoom && <ExitIcon handleClick={onCloseTrial} />}

      {!isAdmin && !isTryingRoom && <ExitIcon handleClick={onLeaveSession} />}

      {isAudioEnabled && !isAttendee && (
        <MuteIcon
          handleClick={() => onToggleMuteMyself({ amIMuted: !amIMuted })}
          isActive={amIMuted}
        />
      )}

      <HelpIcon handleClick={onShowHelpOverlay} />
    </div>
  );
};
export default BottomMenu;
