import React from "react";
import "./styles.css";
import LogoHeader from "../LogoHeader";
import FloatingContainer from "../FloatingContainer";

const infoModal = ({ onClose, roomId, attendeeId }) => {
  return (
    <FloatingContainer offset="invite" onClose={onClose} instructions="">
      <div id="invite-cont" className="column">
        <LogoHeader variant="light" />
        <p className="info-modal-text">
          As an admin, you have two types of users to invite:
        </p>

        <p className="info-modal-text">
          <strong>Active users</strong> who can talk, enter VR, and interact by
          grabbing elements. <span className="highlight">Code: {roomId} </span>
        </p>
        <p className="info-modal-text">
          <strong>Others</strong> who can listen to the discussion or
          presentation but cannot actively participate in VR, like the audience
          members in lectures or seminars.{" "}
          <span className="highlight">Code: {attendeeId}</span>
        </p>

        <p className="info-modal-text">
          Share the appropriate code with the individuals you want to invite to
          your session, ensuring they have the right level of participation.
        </p>
      </div>
    </FloatingContainer>
  );
};
export default infoModal;
