const maxSessionTime = 900000; // 15 mins
// const maxSessionTime = 60000;

export function checkTouchScreen() {
  let hasTouchScreen = false;

  if ("maxTouchPoints" in navigator) {
    hasTouchScreen = navigator.maxTouchPoints > 0;
  } else if ("msMaxTouchPoints" in navigator) {
    hasTouchScreen = navigator.msMaxTouchPoints > 0;
  } else {
    const mQ = window.matchMedia && matchMedia("(pointer:coarse)");
    if (mQ && mQ.media === "(pointer:coarse)") {
      hasTouchScreen = !!mQ.matches;
    } else if ("orientation" in window) {
      hasTouchScreen = true; // deprecated, but good fallback
    } else {
      // Only as a last resort, fall back to user agent sniffing
      const UA = navigator.userAgent;
      hasTouchScreen =
        /\b(BlackBerry|webOS|iPhone|IEMobile)\b/i.test(UA) ||
        /\b(Android|Windows Phone|iPad|iPod)\b/i.test(UA);
    }
  }

  return hasTouchScreen;
}

export function saveSessionCookie(id, userType) {
  const expire = new Date(Date.now() + maxSessionTime);
  document.cookie = `mwxr-session=${id}; expires=${expire};`;
  document.cookie = `mwxr-user=${userType}; expires=${expire};`;
}

export function checkSessionCookie(){
  const session = document.cookie
  .split("; ")
  .find((row) => row.startsWith("mwxr-session="))
  ?.split("=")[1];

  return session;
}

export function checkAdminCookie(){
  const user = document.cookie
  .split("; ")
  .find((row) => row.startsWith("mwxr-user="))
  ?.split("=")[1];

  return user;
}

export function clearSessionCookie() {
   document.cookie = `mwxr-session=; expires=Thu, 01 Jan 1970 00:00:00 GMT;`;
}