import React, { useEffect } from "react";
import { PresenceIndicatorContainer } from "../../containers/presenceIndicatorContainer";
import { WelcomeContainer } from "../../containers/welcomeContainer";
import { AudioContainer } from "../../containers/audioContainer";
import { JoystickContainer } from "../../containers/joystickContainer";
import { BottomMenuContainer } from "../../containers/bottomMenuContainer";
import { HelpOverlayContainer } from "../../containers/helpOverlayContainer";
import "./index.css";

import epfl from "../../../../static/images/epfl.png";
import { MenuContainer } from "../../containers/menuContainer";

const confirmationMessage = "You are about to close this session. Continue?";

const useConfirmTabClose = (isUnsafeTabClose) => {
  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (isUnsafeTabClose) {
        event.returnValue = confirmationMessage;
        return confirmationMessage;
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => window.removeEventListener("beforeunload", handleBeforeUnload);
  }, [isUnsafeTabClose]);
};

const App = ({
  onStartConnecting,
  isConnectedToASession,
  isAdmin,
  isTouch,
  isTryingRoom,
  isShowingHelp,
}) => {
  useEffect(() => {
    onStartConnecting();
  }, []);

  useConfirmTabClose(isAdmin);

  return (
    <div id="app" className="app">
      <PresenceIndicatorContainer />
      <AudioContainer />
      {isConnectedToASession && !isTryingRoom && isAdmin && <MenuContainer />}
      {!isConnectedToASession && !isTryingRoom && <WelcomeContainer />}
      {(isConnectedToASession || isTryingRoom) && isTouch && (
        <JoystickContainer />
      )}
      {(isConnectedToASession || isTryingRoom) && (
        <HelpOverlayContainer />
      )}
      {(isConnectedToASession || isTryingRoom) && <BottomMenuContainer />}
      {!(isConnectedToASession || isTryingRoom) && (
        <img className="epfl-logo" src={epfl}></img>
      )}
    </div>
  );
};
export default App;
