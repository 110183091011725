import React, { useState } from "react";
import "./styles.css";
import Button from "../Button";
import RoomCard from "../RoomCard";
import Input from "../Input";
import LogoHeader from "../LogoHeader";

const Welcome = ({
  onRequestNewRoom,
  onRequestJoinRoom,
  onRequestTryRoom,
  onResetErrors,
  availableRooms,
  noRoomFound,
  isConnected,
}) => {
  const [isJoining, setIsJoining] = useState(false);
  const [isCreating, setIsCreating] = useState(false);
  const [isLearningMore, setIsLearningMore] = useState(false);
  const [roomName, setRoomName] = useState("");
  const [userName, setUserName] = useState("");

  const handleWantToJoin = () => {
    setIsJoining(!isJoining);
    onResetErrors();
  };

  const handleWantToCreate = () => {
    setIsCreating(!isCreating);
  };

  const handleJoinRoom = () => {
    if (roomName.length > 0 && userName.length > 0) {
      onRequestJoinRoom({
        roomName: roomName.toLowerCase(),
        userType: "",
        userName,
      });
      setRoomName("");
    }
  };

  const handleWantToTry = () => {
    onRequestTryRoom();
  };

  const handleWantToLearnMore = () => {
    setIsLearningMore(!isLearningMore);
  };

  return (
    <div className={`modal-container ${isCreating ? "creating" : ""}`}>
      <div className="welcome-top-section column">
        <LogoHeader variant="dark" withSubtitle />
      </div>
      <div className="welcome-bottom-section column">
        {!isJoining && !isCreating && !isLearningMore && (
          <>
            <p className="text-description margin-top">
              Immerse yourself in collaborative virtual reality sessions with
              your peers, where you can discuss and manipulate objects in
              real-time.
            </p>

            <div className="welcome-btn-row row">
              <Button disabled={!isConnected} handleClick={handleWantToCreate}>
                Create Room
              </Button>
              <Button disabled={!isConnected} handleClick={handleWantToJoin}>
                Join Room
              </Button>
            </div>

            <p className="text-description">
              Or click the button below to explore a sample VR scene!
            </p>
            <div className="welcome-btn-row row">
              <Button disabled={!isConnected} handleClick={handleWantToTry}>
                Give it a try
              </Button>
            </div>

            <p className="text-description">
              <span onClick={handleWantToLearnMore} className="inline-link">
                Click here
              </span>{" "}
              to learn more about us and this project.
            </p>
          </>
        )}

        {isLearningMore && (
          <>
            <p className="text-description">
              MolecularWebXR is the Metaverse’s ultimate immersive tool for
              education and discussion in chemistry and structural biology.
            </p>
            <p className="text-description">
              Access rooms with preset content or setup your own material for
              education and discussions.
            </p>

            <p className="text-description">
              MolecularWebXR was created by Fabio Cortes Rodriguez and Luciano
              Abriata from EPFL, Switzerland. To know more about the website,
              its features and how to use them, click{" "}
              <span>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  className="inline-link"
                  href="https://lucianosphere.medium.com/coming-up-in-the-next-weeks-free-multiuser-immersive-chemistry-and-biology-at-your-fingertips-d4b0ba32cf4b"
                >
                  here.
                </a>
              </span>
            </p>

            <p className="text-description">
              For personalized o customized solutions contact us at luciano.abriata@epfl.ch and fabio.cortesrodriguez@epfl.ch
            </p>

            <Button handleClick={handleWantToLearnMore}>Back to menu</Button>
          </>
        )}

        {isCreating && (
          <>
            <p className="text-description">
              Select one of our pre-built scenes or start with an empty one:
            </p>
            <div className="welcome-card-row">
              {availableRooms.map((room) => (
                <RoomCard
                  key={room.id}
                  name={room.name}
                  imgUrl={room.thumbnail}
                  handleClick={() => onRequestNewRoom({ id: room.id })}
                />
              ))}
            </div>
            <Button handleClick={handleWantToCreate}>Back to menu</Button>
          </>
        )}

        {isJoining && (
          <div className="welcome-type-col column">
            <p className="text-description">
              Type your name and the room id, then click join to enter the
              session
            </p>
            <Input
              placeholder="my-name"
              value={userName}
              onChange={(text) => setUserName(text)}
              variant="dark"
              label="Name"
              className="extra-mgn-top"
            />

            <Input
              placeholder="my-room-id"
              value={roomName}
              onChange={(text) => setRoomName(text)}
              variant="dark"
              error={noRoomFound}
              errorMsg="We couldn't find that room :("
              label="Room ID"
            />

            <div className="welcome-btn-row join row">
              <Button handleClick={handleWantToJoin}>Back to menu</Button>
              <Button handleClick={handleJoinRoom}>Join</Button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
export default Welcome;
