import { createSlice } from "@reduxjs/toolkit";
import { sessionSlice } from "./sessionSlice";

const initialState = {
  spawnPoint: {
    x: 0,
    y: 0,
    z: 0,
  },
  scaling: {
    active: false,
    objects: [],
  },
  spawnRotation: 0,
  color: "",
  myIndex: null,
  canIGrab: false,
  isInVR: false,
  canGrabMultipleObjs: false,
  users: [],
  elements: [],
};

export const sceneSlice = createSlice({
  name: "scene",
  initialState,
  reducers: {
    receivedSpawnPoint: (state, action) => {
      const data = action.payload;
      state.spawnPoint.x = data.x;
      state.spawnPoint.y = data.y;
      state.spawnPoint.z = data.z;
      state.spawnRotation = data.angle;
      state.color = data.color;
      state.myIndex = data.userIndex;
      state.canIGrab = data.canGrab;
    },
    receivedStartingPackage: (state, action) => {
      state.users = action.payload.users;
      state.elements = action.payload.elements;
      state.canGrabMultipleObjs = action.payload.canGrabMultipleObjs;
    },
    userAdded: (state, action) => {
      state.users.push({ ...action.payload, visible: true });
    },
    userUpdatedViibility: (state, action) => {
      const user = state.users.find((user) => user.id === action.payload.id);
      if (user) {
        user.visible = action.payload.visibility;
      }
    },
    userRemoved: (state, action) => {
      const userIndex = state.users.findIndex((user) => user.id === action.payload.id);
      state.users.splice(userIndex, 1);

      state.elements.forEach((element) => {
        if (element.owner === action.payload.id) {
          element.owner = null;
          element.isGrabbed = false;
        }
      });
    },
    permissionUpdated: (state, action) => {
      state.canIGrab = action.payload.canGrab;
    },
    canGrabMultipleObjsUpdated: (state, action) => {
      state.canGrabMultipleObjs = action.payload.canGrabMultipleObjs;
    },
    emitUserPermissionUpdate: (state, action) => {
      const user = state.users.find((user) => user.id === action.payload.id);
      if (user) {
        user.canGrab = action.payload.canGrab;
      }
    },
    emitControllerConnect: (state, action) => {
      state.isInVR = true;
    },
    emitGrabbingMultipleObjs: (state, action) => {
      return;
    },
    emitControllerDisconnect: (state, action) => {
      state.isInVR = false;
    },
    emitControllerUpdate: (state, action) => {
      return;
    },
    emitElementSubmit: (state, action) => {
      return;
    },
    elementAdded: (state, action) => {
      state.elements.push(action.payload.element);
    },
    emitScaleUpdate: (state, action) => {
      return;
    },
    elementScaleUpdated: (state, action) => {
      const element = state.elements.find((element) => element.id === action.payload.element.id);
      if (element) {
        element.scale = action.payload.element.scale;
      }
    },
    elementEnableUpdated: (state, action) => {
      const element = state.elements.find((element) => element.id === action.payload.element.id);
      if (element) {
        element.isEnabled = action.payload.element.isEnabled;
      }
    },
    elementClearGrabbing: (state, action) => {
      const element = state.elements.find((element) => element.id === action.payload.id);
      if (element) {
        element.owner = null;
        element.isGrabbed = false;
      }
    },
    updateElementList: (state, action) => {
      state.elements = action.payload.elements;
    },
    emitElementDelete: (state, action) => {
      return;
    },
    elementDeleted: (state, action) => {
      const elementIndex = state.elements.findIndex((element) => element.id === action.payload.id);
      state.elements.splice(elementIndex, 1);
    },
    emitElementRecenter: (state, action) => {
      return;
    },
    emitElementEnable: (state, action) => {
      return;
    },
    emitElementGrabbed: (state, action) => {
      return;
    },
    emitElementUngrabbed: (state, action) => {
      return;
    },
    elementGrabbingUpdated: (state, action) => {
      const element = state.elements.find((element) => element.id === action.payload.element.id);
      if (element) {
        element.isGrabbed = action.payload.element.isGrabbed;
        element.owner = action.payload.element.owner;
      }
    },
    emitElementUpdate: (state, action) => {
      return;
    },
    startScaling: (state, action) => {
      state.scaling.active = true;
      state.scaling.objects = action.payload;
    },
    stopScaling: (state, action) => {
      state.scaling.active = false;
      state.scaling.objects = [];
    },
    joystickUpdate: (state, action) => {
      return;
    },
    joystickEnd: (state, action) => {
      return;
    },
    emitSlideUpdate: (state, action) => {
      return;
    },
    enterVR: (state, action) => {
      return;
    },
    enterAR: (state, action) => {
      return;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(sessionSlice.actions.connectionLost, (state, action) => {
      state.spawnPoint = {
        x: 0,
        y: 0,
        z: 0,
      };
      state.spawnRotation = 0;
      state.color = "";
      state.myIndex = null;
      state.canIGrab = false;
      state.users = [];
      state.elements = [];
    });

    builder.addCase(sessionSlice.actions.sessionClosed, (state, action) => {
      state.spawnPoint = {
        x: 0,
        y: 0,
        z: 0,
      };
      state.spawnRotation = 0;
      state.color = "";
      state.myIndex = null;
      state.canIGrab = false;
      state.users = [];
      state.elements = [];
    });

    builder.addCase(sessionSlice.actions.requestTrial, (state, action) => {
      state.spawnRotation = Math.PI;
      state.myIndex = 0;
      state.canIGrab = true;
    });

    builder.addCase(sessionSlice.actions.leaveTrial, (state, action) => {
      state.spawnRotation = Math.PI;
      state.myIndex = null;
      state.canIGrab = false;
    });
  },
});
