import { connect } from "react-redux";
import UsersMenu from "../components/UsersMenu";
import { sceneSlice } from "../../store/sceneSlice";
import { sessionSlice } from "../../store/sessionSlice";

const mapStateToProps = (state) => {
  return {
    users: state.scene.users,
    isConnected: state.session.isConnected,
    isAdmin : state.session.userType === "admin",
    isAudioEnabled: state.session.withAudio,
    roomId: state.session.roomId,
    attendeeId: state.session.attendeeId,
    isSessionMuted: state.session.isSessionMuted,
    areGuestsMuted: state.session.areGuestsMuted,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onUpdateGrabbingPermissions: (permission, id) => {
      dispatch(
        sceneSlice.actions.emitUserPermissionUpdate({
          id,
          canGrab: permission,
        })
      );
    },
    toggleAudioChannel: () => {
      dispatch(sessionSlice.actions.emitToggleAudioChannel());
    },
    toggleMute: () => {
      dispatch(sessionSlice.actions.emitToggleMute());
    },
    toggleMuteGuests: () => {
      dispatch(sessionSlice.actions.emitToggleMuteGuests());
    },
  };
};

export const UsersMenuContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(UsersMenu);
