import { connect } from "react-redux";
import Audio from "../components/Audio/index.js";
import { sceneSlice } from "../../store/sceneSlice";
import { sessionSlice } from "../../store/sessionSlice";

const mapStateToProps = (state) => {
  return {
    audioUsers: state.session.audioUsers,
    isConnected: state.session.isConnected,
    rtpCapabilities: state.session.rtpCapabilities,
    isAudioEnabled: state.session.withAudio,
    amIMuted: state.session.amIMuted,
    isAttendee: state.session.userType === "attendee",
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onRequestWebRtcTransport: (data) => {
      dispatch(sessionSlice.actions.emitRequestWebRtcTransport(data));
    },
  };
};

export const AudioContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(Audio);
