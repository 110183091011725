import React from "react";
import "./styles.css";

const UsersIcon = ({ isActive, handleClick }) => {
  return (
    <div className="icon-container icon-margin-btm">
      <a className="icon-anchor" onClick={handleClick}>
        <svg
          className="svg-container element-icon"
          width="100%"
          height="100%"
          viewBox="0 0 512 512"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          xmlSpace="preserve"
          xmlnsserif="http://www.serif.com/"
        >
          <g transform="matrix(1.79021,0,0,1.79021,53.7063,19.6923)">
            <circle
              className={`el-circle ${isActive ? "active" : ""}`}
              cx="113"
              cy="132"
              r="143"
            />
          </g>
          <g transform="matrix(15.375,0,0,15.375,71.5,71.5)">
            <path
              d="M12,6C13.1,6 14,6.9 14,8C14,9.1 13.1,10 12,10C10.9,10 10,9.1 10,8C10,6.9 10.9,6 12,6M12,16C14.7,16 17.8,17.29 18,18L6,18C6.23,17.28 9.31,16 12,16M12,4C9.79,4 8,5.79 8,8C8,10.21 9.79,12 12,12C14.21,12 16,10.21 16,8C16,5.79 14.21,4 12,4ZM12,14C9.33,14 4,15.34 4,18L4,20L20,20L20,18C20,15.34 14.67,14 12,14Z"
              className="el-path"
            />
          </g>
        </svg>
        {!isActive && (
          <div className="tooltip">
            <p>Users</p>
            <div className="triangle"></div>
          </div>
        )}
      </a>
    </div>
  );
};
export default UsersIcon;
