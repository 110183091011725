import React from "react";
import "./styles.css";

const FloatingContainer = ({
  children,
  onClose,
  instructions = "",
  offset,
  className = "",
}) => {
  return (
    <div className={`floating-container ${className}`}>
      <div
        className={`triangle-mk ${offset}`}
      ></div>
      <div className="close-menu" onClick={onClose}>
        <div className="bar-1"></div>
        <div className="bar-2"></div>
      </div>
      <div className="column">
        {instructions.length > 0 && (
          <div className="settings-info row">
            <p>👉</p>
            <p className="settings-instructions">{instructions}</p>
          </div>
        )}
        {children}
      </div>
    </div>
  );
};
export default FloatingContainer;
