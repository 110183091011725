import React from "react";
import "./styles.css";
import FloatingContainer from "../FloatingContainer";
import Switch from "../Switch";
import Button from "../Button";

const UsersMenu = ({
  onClose,
  onUpdateGrabbingPermissions,
  users,
  isAdmin,
  toggleAudioChannel,
  isConnected,
  isAudioEnabled,
  roomId,
  attendeeId,
  toggleMute,
  toggleMuteGuests,
  areGuestsMuted,
  isSessionMuted,
}) => {
  const onEnableAudio = () => {
    toggleAudioChannel();
  };

  return (
    <FloatingContainer
      offset="users"
      onClose={onClose}
      className="elements"
      instructions="Here you can enable the audio channel for the session and control if the users can grab elements in the scene."
    >
      {isAdmin && isConnected && (
        <div className="switch-row row">
          <p className="list-text">Audio</p>
          <Switch
            id="audio"
            isEnabled={isAudioEnabled}
            handleClick={onEnableAudio}
          />
        </div>
      )}

      {isAudioEnabled && isAdmin && (
        <div className="row audio-btns">
          <Button
            variant="small"
            className="btn-mgn-right"
            handleClick={toggleMute}
          >
            {isSessionMuted ? "Unmute" : "Mute"} all
          </Button>
        </div>
      )}

      {/* {elements.length > 0 && isAdmin && (
        <div className="switch-row row">
          <p className="list-text">Grab multiple elements</p>
          <Switch
            id="mutiple-objs"
            isEnabled={canGrabMultipleObjs}
            handleClick={onUpdateGrabbingMultipleObjs}
          />
        </div>
      )} */}

      {users.length === 0 && (
        <p className="list-title msg-text">
          Currently there are no users in VR
        </p>
      )}
      {users.length > 0 && (
        <div className="list-container column">
          <p className="list-title">Users</p>
          <div className="scroll-list">
            {users.map((user) => (
              <div className="list-row user-row row" key={`${user.id}`}>
                <p className="list-text">
                  {user.name} - {user.color}
                </p>
                {isAdmin && (
                  <Switch
                    id={user.id}
                    isEnabled={user.canGrab}
                    handleClick={onUpdateGrabbingPermissions}
                    tooltip="Can grab?"
                  />
                )}
              </div>
            ))}
          </div>
        </div>
      )}
    </FloatingContainer>
  );
};
export default UsersMenu;
