import React, { useEffect } from "react";
import logo1 from "../../../assets/img/logo1.png";
import logo2 from "../../../assets/img/logo2.png";
import "./styles.css";

const LogoHeader = ({ variant = "light", withSubtitle = false }) => {
  return (
    <div className="logo-container column">
      <figure>
        <img
          className="logo-header"
          src={variant === "dark" ? logo1 : logo2}
        ></img>
      </figure>
      <h1 className={`logo-title ${variant}`}>MolecularWebXR</h1>
      {withSubtitle && (
        <p className={`logo-subtitle ${variant}`}>
          Welcome to the new era of scientific collaboration!
        </p>
      )}
    </div>
  );
};
export default LogoHeader;
