import { connect } from "react-redux";
import BottomMenu from "../components/BottomMenu";
import { sceneSlice } from "../../store/sceneSlice";
import { sessionSlice } from "../../store/sessionSlice";

const mapStateToProps = (state) => {
  return {
    isConnectedToASession: state.session.isConnectedToASession,
    isVrUser: state.session.isVr,
    isArUser: state.session.isAr,
    isAdmin: state.session.userType === "admin",
    isAttendee: state.session.userType === "attendee",
    isTouch: state.session.isTouch,
    isAudioEnabled: state.session.withAudio,
    amIMuted: state.session.amIMuted,
    roomId: state.session.roomId,
    isTryingRoom: state.session.isTryingRoom,
    isConnectedToASession: state.session.isConnectedToASession,
    isShowingInfoModal: state.session.isShowingInfoModal,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onEnterVR: () => {
      dispatch(sceneSlice.actions.enterVR());
    },
    onEnterAR: () => {
      dispatch(sceneSlice.actions.enterAR());
    },
    onCloseSession: () => {
      dispatch(sessionSlice.actions.emitCloseSession());
    },
    onLeaveSession: () => {
      dispatch(sessionSlice.actions.emitLeaveSession());
    },
    onToggleMuteMyself: (data) => {
      dispatch(sessionSlice.actions.toggleMuteMyself(data));
    },
    onCloseTrial: () => {
      dispatch(sessionSlice.actions.leaveTrial());
    },
    onShowHelpOverlay: () => {
      dispatch(sessionSlice.actions.toggleHelp());
    },
  };
};

export const BottomMenuContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(BottomMenu);
