import { connect } from "react-redux";
import infoModal from "../components/infoModal";
import { sceneSlice } from "../../store/sceneSlice";
import { sessionSlice } from "../../store/sessionSlice";

const mapStateToProps = (state) => {
  return {
    roomId: state.session.roomId,
    attendeeId: state.session.attendeeId
  };
};

export const InfoModalContainer = connect(
  mapStateToProps,
)(infoModal);
