import React from "react";
import "./styles.css";

const ZoomOutIcon = ({ handleClick }) => {
  return (
    <div className="icon-container list">
      <a className="icon-anchor" onClick={handleClick}>
        <svg
          className="svg-container-small element-icon"
          width="100%"
          height="100%"
          viewBox="0 0 512 512"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          xmlSpace="preserve"
          xmlnsserif="http://www.serif.com/"
        >
          <g
            className="el-path-small delete"
            transform="matrix(24.0714,0,0,24.0714,-32.8571,-32.8571)"
          >
            <path
              d="M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z"
            />
          </g>
        </svg>

        <div className="tooltip-small">
          <p>Delete</p>
        </div>
      </a>
    </div>
  );
};
export default ZoomOutIcon;
