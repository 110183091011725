import { connect } from 'react-redux';
import PresenceIndicator from '../components/PresenceIndicator';

const mapStateToProps = (state) => {
  return {
    isConnected: state.session.isConnected
  };
};

export const PresenceIndicatorContainer = connect(
  mapStateToProps,
)(PresenceIndicator);