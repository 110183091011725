import React, { useRef, useEffect } from "react";
import "./index.css";
import nipplejs from "nipplejs";

const Joystick = ({ onJoystickUpdate, onJoystickEnd }) => {
  let joystickManager;
  const joystickRef = useRef(null);
  useEffect(() => {
    const options = {
      zone: joystickRef.current,
      size: 120,
      multitouch: true,
      maxNumberOfNipples: 2,
      mode: "static",
      restJoystick: true,
      shape: "circle",
      // position: { top: 20, left: 20 },
      position: { top: "60px", left: "60px" },
      dynamicPage: true,
    };

    joystickManager = nipplejs.create(options);
    joystickManager["0"].on("move", function (evt, data) {
      onJoystickUpdate({ x: data.vector.x, y: data.vector.y });
    });
    joystickManager["0"].on("end", function (evt, data) {
      onJoystickEnd()
    });
  }, []);
  return (
    <div ref={joystickRef} className="joystick-container">
      <div className="joystick-wrapper1"></div>
    </div>
  );
};
export default Joystick;
