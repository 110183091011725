import * as React from "react";
import * as ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { ApplicationContainer } from "./ui/containers/applicationContainer";
import "./style.css";
import { store } from "./store";
import { setup3DScene } from "./3d";

const domContainer = document.querySelector("#ui");
const root = ReactDOM.createRoot(domContainer);
root.render(
  <Provider store={store}>
    <ApplicationContainer />
  </Provider>
);

setup3DScene();