import React from "react";
import "./styles.css";

const MuteIcon = ({ isActive, handleClick }) => {
  return (
    <div className="icon-container">
      <a className="icon-anchor" onClick={handleClick}>
        <svg
          className="svg-container element-icon"
          width="100%"
          height="100%"
          viewBox="0 0 512 512"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          xmlSpace="preserve"
          xmlnsserif="http://www.serif.com/"
        >
          <g transform="matrix(1.79021,0,0,1.79021,53.7063,19.6923)">
            <circle
              className={`el-circle ${isActive ? "mute" : "unmute"}`}
              cx="113"
              cy="132"
              r="143"
            />
          </g>
          {!isActive ? (
            <g id="mic" transform="matrix(18.2487,0,0,17.6316,37.0156,35.6053)">
              <path
                className={`el-path ${isActive ? "mute" : "unmute"}`}
                d="M12,15C13.66,15 14.99,13.66 14.99,12L15,6C15,4.34 13.66,3 12,3C10.34,3 9,4.34 9,6L9,12C9,13.66 10.34,15 12,15ZM18.08,12C17.66,12 17.31,12.3 17.25,12.71C16.88,15.32 14.53,17.1 12,17.1C9.47,17.1 7.12,15.33 6.75,12.71C6.69,12.3 6.33,12 5.92,12C5.4,12 5,12.46 5.07,12.97C5.53,15.94 8.03,18.27 11,18.72L11,21C11,21.55 11.45,22 12,22C12.55,22 13,21.55 13,21L13,18.72C15.96,18.29 18.47,15.94 18.93,12.97C19,12.46 18.6,12 18.08,12Z"
              />
            </g>
          ) : (
            <g>
              <g transform="matrix(18.2487,0,0,17.6316,37.0156,35.6053)">
                <path
                  className={`el-path ${isActive ? "mute" : "unmute"}`}
                  d="M12,15C13.66,15 14.99,13.66 14.99,12L15,6C15,4.34 13.66,3 12,3C10.34,3 9,4.34 9,6L9,12C9,13.66 10.34,15 12,15ZM18.08,12C17.66,12 17.31,12.3 17.25,12.71C16.88,15.32 14.53,17.1 12,17.1C9.47,17.1 7.12,15.33 6.75,12.71C6.69,12.3 6.33,12 5.92,12C5.4,12 5,12.46 5.07,12.97C5.53,15.94 8.03,18.27 11,18.72L11,21C11,21.55 11.45,22 12,22C12.55,22 13,21.55 13,21L13,18.72C15.96,18.29 18.47,15.94 18.93,12.97C19,12.46 18.6,12 18.08,12Z"
                />
              </g>
              <g transform="matrix(0.965824,-0.0298524,-0.0618092,0.938163,24.5722,23.4724)">
                <path
                  className={`el-path ${isActive ? "mute" : "unmute"}`}
                  d="M129.391,88.5L382.609,423.5"
                  style={{ strokeWidth: "30.9px" }}
                />
              </g>
            </g>
          )}
        </svg>
        <div className="tooltip-btm">
          <p>{isActive ? "Unmute" : "Mute"}</p>
          <div className="triangle-btm"></div>
        </div>
      </a>
    </div>
  );
};
export default MuteIcon;
