import React from "react";
import "./styles.css";

const InviteIcon = ({ isActive, handleClick }) => {
  return (
    <div className="icon-container" id="invite">
      <a className="icon-anchor" onClick={handleClick}>
        <svg
          className="svg-container element-icon"
          width="100%"
          height="100%"
          viewBox="0 0 512 512"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          xmlSpace="preserve"
          xmlnsserif="http://www.serif.com/"
        >
          <g transform="matrix(1.79021,0,0,1.79021,53.7063,19.6923)">
            <circle
              className={`el-circle ${isActive ? "active" : ""}`}
              cx="113"
              cy="132"
              r="143"
            />
          </g>

          <g transform="matrix(0.89043,0,0,0.89043,40.6121,139.527)">
            <g transform="matrix(288,0,0,288,58.3765,233.18)">
              <path
                className="el-path"
                d="M0.326,-0.176L0.487,-0.711L0.65,-0.711L0.403,-0L0.25,-0L0.003,-0.711L0.166,-0.711L0.326,-0.176Z"
              />
            </g>
            <g transform="matrix(288,0,0,288,246.673,233.18)">
              <path
                className="el-path"
                d="M0.327,-0.26L0.21,-0.26L0.21,-0L0.063,-0L0.063,-0.711L0.328,-0.711C0.412,-0.711 0.476,-0.692 0.522,-0.655C0.568,-0.617 0.59,-0.564 0.59,-0.496C0.59,-0.448 0.58,-0.407 0.559,-0.375C0.538,-0.342 0.506,-0.317 0.463,-0.297L0.617,-0.007L0.617,-0L0.46,-0L0.327,-0.26ZM0.21,-0.379L0.328,-0.379C0.365,-0.379 0.393,-0.388 0.414,-0.407C0.434,-0.426 0.444,-0.451 0.444,-0.484C0.444,-0.518 0.434,-0.544 0.415,-0.563C0.396,-0.583 0.367,-0.592 0.328,-0.592L0.21,-0.592L0.21,-0.379Z"
              />
            </g>
          </g>
        </svg>
        {!isActive && (
          <div className="tooltip-btm">
            <p>Enter VR</p>
            <div className="triangle-btm"></div>
          </div>
        )}
      </a>
    </div>
  );
};
export default InviteIcon;
