import React, { useState, useEffect } from "react";
import { ElementsMenuContainer } from "../../containers/elementsMenuContainer";
import { UsersMenuContainer } from "../../containers/usersMenuContainer";
import ElementsIcon from "../ElementsIcon";
import UsersIcon from "../UsersIcon";
import InviteIcon from "../InviteIcon";
import "./styles.css";
import { InfoModalContainer } from "../../containers/infoModalContainer";

const Menu = ({ onToggleInfoModal, isShowingInfoModal }) => {
  const [isElMenuActive, setIsElMenuActive] = useState(false);
  const [isUserMenuActive, setIsUserMenuActive] = useState(false);
  const [isInviteMenuActive, setIsInviteMenuActive] = useState(true);
  const [shouldUpdate, setShouldUpdate] = useState(false);

  const handleElIconClick = () => {
    if (!isElMenuActive) {
      setIsUserMenuActive(false);
      setIsInviteMenuActive(false);
    }
    setIsElMenuActive(!isElMenuActive);
    onToggleInfoModal();
    setShouldUpdate(false)
  };
  const handleUserIconClick = () => {
    if (!isUserMenuActive) {
      setIsElMenuActive(false);
      setIsInviteMenuActive(false);
    }
    setIsUserMenuActive(!isUserMenuActive);
    onToggleInfoModal();
    setShouldUpdate(false)
  };

  const handleInviteIconClick = () => {
    if (!isInviteMenuActive) {
      setIsUserMenuActive(false);
      setIsElMenuActive(false);
      setShouldUpdate(false)
    }
    setIsInviteMenuActive(!isInviteMenuActive);
    onToggleInfoModal();
    setShouldUpdate(false)
  };

  useEffect(() => {
    if(!isShowingInfoModal && shouldUpdate) {
      setIsUserMenuActive(false);
      setIsElMenuActive(false);
      setIsInviteMenuActive(false);
    }
    setShouldUpdate(true)
  },[isShowingInfoModal]);

  return (
    <>
      <div className="column side-menu">
        <ElementsIcon
          isActive={isElMenuActive}
          handleClick={handleElIconClick}
        />
        <UsersIcon
          isActive={isUserMenuActive}
          handleClick={handleUserIconClick}
        />
        <InviteIcon
          handleClick={handleInviteIconClick}
          isActive={isInviteMenuActive}
        />
      </div>
      {isElMenuActive && <ElementsMenuContainer onClose={handleElIconClick} />}

      {isUserMenuActive && <UsersMenuContainer onClose={handleUserIconClick} />}

      {isInviteMenuActive && (
        <InfoModalContainer onClose={handleInviteIconClick} />
      )}
    </>
  );
};
export default Menu;
