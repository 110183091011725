import React from "react";
import "./styles.css";

const Button = ({
  variant,
  children,
  handleClick,
  className,
  id = "",
  disabled,
  active = false,
}) => {
  return (
    <button
      id={id}
      className={`btn ${className} ${variant} ${active ? "active" : ""}`}
      disabled={disabled}
      onClick={handleClick}
    >
      {children}
    </button>
  );
};
export default Button;
