import { createSelector } from "@reduxjs/toolkit";

const sceneState = (state) => state.scene;
const sessionState = (state) => state.session;

export const selectSpawnPoint = createSelector(
  sceneState,
  (scene) => scene.spawnPoint
);

export const selectColor = createSelector(sceneState, (scene) => scene.color);

export const selectSpawnRotation = createSelector(
  sceneState,
  (scene) => scene.spawnRotation
);

export const selectIsConnected = createSelector(
  sessionState,
  (session) => session.isConnected
);

export const selectIsShowingInfoModal = createSelector(
  sessionState,
  (session) => session.isShowingInfoModal
);

export const selectIsShowingHelpOverlay = createSelector(
  sessionState,
  (session) => session.isShowingOverlay
);

export const selectUsers = createSelector(sceneState, (scene) => scene.users);

export const selectAvailableElements = createSelector(
  [sceneState, sessionState],
  (scene, session) => {
    if (session.isTryingRoom) {
      return session.trialScene.elements.filter((el) => el.isEnabled);
    } else {
      return scene.elements.filter(
        (el) => el.isEnabled && (!el.isGrabbed || el.owner === session.id)
      );
    }
  }
);

export const selectScaling = createSelector(
  sceneState,
  (scene) => scene.scaling
);

export const selectGrabbingPermission = createSelector(
  sceneState,
  (scene) => scene.canIGrab
);

export const selectisInVR = createSelector(sceneState, (scene) => scene.isInVR);

export const selectisInTrialMode = createSelector(
  sessionState,
  (session) => session.isTryingRoom
);

export const selectisTyping = createSelector(
  sessionState,
  (session) => session.isTyping
);

export const selectMultipleGrabbingPermission = createSelector(
  sceneState,
  (scene) => scene.canGrabMultipleObjs
);

export const selectSceneStats = createSelector(sceneState, (scene) => ({
  users: scene.users.length,
  elements: scene.elements.length,
}));
