import { connect } from "react-redux";
import Welcome from "../components/Welcome";
import { sessionSlice } from "../../store/sessionSlice";

const mapStateToProps = (state) => {
  return {
    isConnectedToASession: state.session.isConnectedToASession,
    isConnected: state.session.isConnected,
    availableRooms: state.session.availableRooms,
    noRoomFound: state.session.noRoomFound
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onRequestJoinRoom: (data) => {
      dispatch(sessionSlice.actions.emitRequestJoinRoom(data));
    },
    onRequestNewRoom: (data) => {
      dispatch(sessionSlice.actions.emitRequestNewRoom(data));
    },
    onRequestTryRoom: () => {
      dispatch(sessionSlice.actions.requestTrial());
    },
    onResetErrors : () => {
      dispatch(sessionSlice.actions.resetErrors());
    }
  };
};

export const WelcomeContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(Welcome);
