import React from "react";
import "./styles.css";

const CloseIcon = ({ isActive, handleClick }) => {
  return (
    <div className="icon-container" id="invite">
      <a className="icon-anchor" onClick={handleClick}>
        <svg
          className="svg-container element-icon"
          width="100%"
          height="100%"
          viewBox="0 0 512 512"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          xmlSpace="preserve"
          xmlnsserif="http://www.serif.com/"
        >
          <g transform="matrix(1.79021,0,0,1.79021,53.7063,19.6923)">
            <circle
              className={`el-circle-close ${isActive ? "active" : ""}`}
              cx="113"
              cy="132"
              r="143"
            />
          </g>
          <g
            className="el-path-close"
            transform="matrix(7.5625,0,0,7.5625,74.5,83.1212)"
          >
            <path
              d="M24,18C20.79,18 17.7,18.5 14.8,19.44L14.8,25.65C14.8,26.44 14.34,27.12 13.68,27.45C11.73,28.43 9.94,29.68 8.35,31.15C7.99,31.5 7.5,31.72 6.95,31.72C6.4,31.72 5.9,31.5 5.54,31.13L0.59,26.18C0.22,25.81 0,25.31 0,24.76C0,24.21 0.22,23.71 0.59,23.34C6.68,17.55 14.93,14 24,14C33.07,14 41.32,17.55 47.41,23.34C47.78,23.7 48,24.21 48,24.76C48,25.31 47.78,25.81 47.41,26.17L42.46,31.12C42.1,31.48 41.6,31.71 41.05,31.71C40.51,31.71 40.01,31.49 39.65,31.14C38.055,29.663 36.261,28.418 34.32,27.44C33.637,27.101 33.202,26.403 33.2,25.64L33.2,19.43C30.226,18.479 27.122,17.996 24,18Z"
            />
          </g>
        </svg>
        {!isActive && (
          <div className="tooltip-btm">
            <p>Close session</p>
            <div className="triangle-btm"></div>
          </div>
        )}
      </a>
    </div>
  );
};
export default CloseIcon;
