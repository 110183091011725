import { connect } from "react-redux";
import HelpOverlay from "../components/HelpOverlay";
import { sceneSlice } from "../../store/sceneSlice";
import { sessionSlice } from "../../store/sessionSlice";

const mapStateToProps = (state) => {
  return {
    isActive: state.session.isShowingOverlay
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onClose: () => {
      dispatch(sessionSlice.actions.toggleHelp());
    },
  };
};

export const HelpOverlayContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(HelpOverlay);
