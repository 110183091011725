import { connect } from "react-redux";
import Joystick from "../components/Joystick";
import { sessionSlice } from "../../store/sessionSlice";
import { sceneSlice } from "../../store/sceneSlice";

const mapStateToProps = (state) => {
  return {
    isTouch: state.session.isTouch,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onJoystickUpdate: (data) => {
      dispatch(sceneSlice.actions.joystickUpdate(data));
    },
    onJoystickEnd: (data) => {
      dispatch(sceneSlice.actions.joystickEnd(data));
    }
  };
};

export const JoystickContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(Joystick);
