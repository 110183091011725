import * as mediasoupClient from "mediasoup-client";
import React, { useState, useRef, useEffect } from "react";
import { socket as socket } from "../../../store/socket";

let device;
let producerTransport;
let producer;
let consumer;
let consumerTransport;
let audioUsers = [];

let audioParams = {
  // mediasoup params
  encodings: [],
  // https://mediasoup.org/documentation/v3/mediasoup-client/api/#ProducerCodecOptions
  codecOptions: {
    videoGoogleStartBitrate: 1000,
  },
};

const tracks = [];

function Audio({
  isConnected,
  rtpCapabilities,
  isAudioEnabled,
  amIMuted,
  isAttendee,
}) {
  const [isPaused, setIsPaused] = useState(false);
  const [isReady, setIsReady] = useState(false);
  const [isMutedByAdmin, setIsMutedByAdmin] = useState(false);
  const audioRef = useRef(null);

  async function connectRecvTransport(id) {
    await socket.emit(
      "consume",
      {
        rtpCapabilities: device.rtpCapabilities,
        producerId: id, // ID del producer al que me quiero conectar
        consumerTransportId: consumerTransport.id, // ID del consumer que quiero usar para recibir
      },
      async ({ params }) => {
        if (params.error) {
          console.log("Cannot Consume");
          return;
        }

        // then consume with the local consumer transport
        // which creates a consumer
        consumer = await consumerTransport.consume({
          id: params.id,
          producerId: params.producerId,
          kind: params.kind,
          rtpParameters: params.rtpParameters,
        });

        // destructure and retrieve the video track from the producer
        const { track } = consumer;

        tracks.push(track);
        // consumers.push(consumer)

        audioRef.current.srcObject = new MediaStream(tracks);

        // console.log(`Consumer ${consumer.id} trying to connect to ${id}`);

        // the server consumer started with media paused
        // so we need to inform the server to resume
        socket.emit("consumerResume", { consumerId: consumer.id });
      }
    );
  }

  async function createRecvTransport() {
    if (!device) {
      const rtp = JSON.parse(JSON.stringify(rtpCapabilities));
      await createDevice(rtp);
    }

    await socket.emit(
      "request:webRtcTransport",
      { sender: false },
      ({ params }) => {
        // The server sends back params needed
        // to create Send Transport on the client side
        if (params.error) {
          console.log(params.error);
          return;
        }

        consumerTransport = device.createRecvTransport(params);

        consumerTransport.on(
          "connect",
          async ({ dtlsParameters }, callback, errback) => {
            try {
              await socket.emit("transportRecvConnect", {
                dtlsParameters,
                id: params.id,
              });
              callback();
            } catch (error) {
              errback(error);
            }
          }
        );
        handleReceiveAudio();
      }
    );
  }

  async function connectSendTransport() {
    producer = await producerTransport.produce(audioParams);

    producer.on("trackended", () => {
      console.log("track ended");
      // close audio track
    });

    producer.on("transportclose", () => {
      console.log("transport ended");
      // close audio track
    });
  }

  async function createSendTransport() {
    socket.emit("request:webRtcTransport", { sender: true }, ({ params }) => {
      if (params.error) {
        console.log(params.error);
        return;
      }

      producerTransport = device.createSendTransport(params);

      producerTransport.on(
        "connect",
        async ({ dtlsParameters }, callback, errback) => {
          try {
            await socket.emit("transportConnect", {
              id: params.id,
              dtlsParameters,
            });

            callback();
          } catch (error) {
            errback(error);
          }
        }
      );

      producerTransport.on("produce", async (parameters, callback, errback) => {
        try {
          await socket.emit(
            "transportProduce",
            {
              kind: parameters.kind,
              rtpParameters: parameters.rtpParameters,
              appData: parameters.appData,
              id: params.id,
            },
            ({ id }) => {
              callback({ id });
              // console.log("Producer ID", id);
              setIsReady(true);
            }
          );
        } catch (error) {
          errback(error);
        }
      });
      connectSendTransport();
    });
  }

  async function createDevice(routerRtpCapabilities) {
    try {
      device = new mediasoupClient.Device();
      await device.load({ routerRtpCapabilities });
    } catch (error) {
      if (error.name === "UnsupportedError") {
        console.error("browser not supported");
      }
    }
  }

  const handleReceiveAudio = async () => {
    audioUsers.forEach((user) => {
      if (user.id !== socket.id && user.producerId !== "") {
        // console.log("Trying to connect to: ", user.id);
        connectRecvTransport(user.producerId);
      }
    });
  };

  const handleGetAudio = async () => {
    // Producing
    if (!device) {
      const rtp = JSON.parse(JSON.stringify(rtpCapabilities));
      await createDevice(rtp);
    }

    if (!isAttendee) {
      await createSendTransport();
    }

    // Consuming
    await createRecvTransport();
  };

  const handleConnect = () => {
    socket.emit("request:audioUsers", {}, (response) => {
      audioUsers = response.audioUsers;
    });

    handleGetAudio();

    socket.on("newUser", onNewUser);

    socket.on("mute", onMute);

    socket.on("unmute", onUnmute);
  };

  const handleDisconnect = () => {
    if (producer) {
      producerTransport.close();
    }

    if(consumer) {
      consumerTransport.close();
    }

    producer = null;
    consumer = null;
    consumerTransport = null;
    producerTransport = null;
    audioUsers = [];

    audioParams = {
      encodings: [],
      codecOptions: {
        videoGoogleStartBitrate: 1000,
      },
    };

    setIsReady(false);
  };

  function onNewUser(data) {
    // console.log("Trying to connect to: ", data.id);
    console.log("New user")
    connectRecvTransport(data.producerId);
  }

  function onMute() {
    // setIsMutedByAdmin(true);
    setIsPaused(true);
    producer.pause();
    // consumer.pause();
  }

  function onUnmute() {
    // setIsMutedByAdmin(false);
    setIsPaused(false);
    producer.resume();
    // consumer.pause();
  }

  useEffect(() => {
    navigator.mediaDevices
      .getUserMedia({ video: false, audio: true })
      .then(async (stream) => {
        const track = stream.getAudioTracks()[0];
        audioParams = {
          track,
          ...audioParams,
        };
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    if (isAudioEnabled) {
      handleConnect();
    } else if (
      (!isAudioEnabled && isConnected) ||
      (isAudioEnabled && !isConnected)
    ) {
      handleDisconnect();
    }
    return () => {
      socket.off("mute", onMute);
      socket.off("unmute", onUnmute);
      socket.off("newUser", onNewUser);
    };
  }, [isAudioEnabled]);

  useEffect(() => {
    if (isReady) {
      if (amIMuted) {
        onMute();
      } else {
        onUnmute();
      }
    }
  }, [amIMuted, isReady]);

  const handlePause = () => {
    if (isPaused) {
      producer.resume();
      socket.emit("producerResume", { id: producer.id });
      setIsPaused(false);
    } else {
      producer.pause();
      socket.emit("producerPause", { id: producer.id });
      setIsPaused(true);
    }
  };

  const handleMuteSession = () => {
    socket.emit("toggleMuteSession");
  };

  return (
    <div>
      <figure>
        <audio autoPlay ref={audioRef}></audio>
      </figure>
    </div>
  );
}

export default Audio;
