import React from "react";
import "./styles.css";

const ZoomInIcon = ({ handleClick }) => {
  return (
    <div className="icon-container list">
      <a className="icon-anchor" onClick={handleClick}>
        <svg
          className="svg-container-small element-icon"
          width="100%"
          height="100%"
          viewBox="0 0 512 512"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          xmlSpace="preserve"
          xmlnsserif="http://www.serif.com/"
        >
          <g
            className="el-path-small"
            transform="matrix(19.8439,0,0,19.8439,27.2137,27.2307)"
          >
            <path
              d="M15.5,14L14.71,14L14.43,13.73C15.63,12.33 16.25,10.42 15.91,8.39C15.44,5.61 13.12,3.39 10.32,3.05C6.09,2.53 2.54,6.09 3.05,10.32C3.39,13.12 5.61,15.44 8.39,15.91C10.42,16.25 12.33,15.63 13.73,14.43L14,14.71L14,15.5L18.26,19.75C18.67,20.16 19.33,20.16 19.74,19.75L19.75,19.74C20.16,19.33 20.16,18.67 19.75,18.26L15.5,14ZM9.5,14C7.01,14 5,11.99 5,9.5C5,7.01 7.01,5 9.5,5C11.99,5 14,7.01 14,9.5C14,11.99 11.99,14 9.5,14ZM9.5,7C9.22,7 9,7.22 9,7.5L9,9L7.5,9C7.22,9 7,9.22 7,9.5C7,9.78 7.22,10 7.5,10L9,10L9,11.5C9,11.78 9.22,12 9.5,12C9.78,12 10,11.78 10,11.5L10,10L11.5,10C11.78,10 12,9.78 12,9.5C12,9.22 11.78,9 11.5,9L10,9L10,7.5C10,7.22 9.78,7 9.5,7Z"
            />
          </g>
        </svg>

        <div className="tooltip-small">
          <p>Scale up</p>
        </div>
      </a>
    </div>
  );
};
export default ZoomInIcon;
