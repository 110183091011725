import { connect } from "react-redux";
import { sessionSlice } from "../../store/sessionSlice";
import Menu from "../components/Menu";

const mapStateToProps = (state) => {
  return {
    isAdmin: state.session.userType === "admin",
    isShowingInfoModal: state.session.isShowingInfoModal,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onToggleInfoModal: () => {
      dispatch(sessionSlice.actions.toggleInfoModal())
    }
  };
};

export const MenuContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(Menu);
