import { configureStore } from "@reduxjs/toolkit";
import { sceneSlice } from "./sceneSlice";
import { sessionSlice } from "./sessionSlice";
import socketMiddleware from "./socketMiddleware";

export const store = configureStore({
  reducer: {
    session: sessionSlice.reducer,
    scene: sceneSlice.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      thunk: false,
    }).concat(socketMiddleware),
});
