import { connect } from "react-redux";
import ElementsMenu from "../components/ElementsMenu";
import { sceneSlice } from "../../store/sceneSlice";
import { sessionSlice } from "../../store/sessionSlice";

const mapStateToProps = (state) => {
  return {
    elements: state.scene.elements,
    isAdmin: state.session.userType === "admin",
    canGrabMultipleObjs: state.scene.canGrabMultipleObjs,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onSubmit: (data) => {
      dispatch(sceneSlice.actions.emitElementSubmit(data));
    },
    onScale: (id, type) => {
      dispatch(sceneSlice.actions.emitScaleUpdate({ id, type }));
    },
    onDelete: (id, type) => {
      dispatch(sceneSlice.actions.emitElementDelete({ id }));
    },
    onRecenter: (id) => {
      dispatch(sceneSlice.actions.emitElementRecenter({ id }));
    },
    onToggleEnable: (id) => {
      dispatch(sceneSlice.actions.emitElementEnable({ id }));
    },
    onSlideSubmit: (data) => {
      dispatch(sceneSlice.actions.emitSlideUpdate(data));
    },
    onUpdateGrabbingMultipleObjs: (permission) => {
      dispatch(
        sceneSlice.actions.emitGrabbingMultipleObjs({
          canGrabMultipleObjs: permission,
        })
      );
    },
    onReset: () => {
      dispatch(sessionSlice.actions.emitResetScene());
    },
  };
};

export const ElementsMenuContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ElementsMenu);
