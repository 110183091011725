import React from "react";
import "./styles.css";

const Switch = ({ id, isEnabled, handleClick, tooltip = "" }) => {
  return (
    <div className="switch">
      <input
        id={`switch-${id}`}
        // defaultChecked={isEnabled}
        checked={isEnabled}
        type="checkbox"
        className="switch-input"
        onChange={(e) => handleClick(e.target.checked, id)}
      />
      <label htmlFor={`switch-${id}`} className="switch-label">
        Switch
      </label>
      {tooltip.length > 0 && (
        <div className="tooltip-switch">
          <p>{tooltip}</p>
        </div>
      )}
    </div>
  );
};
export default Switch;
